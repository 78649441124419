
export type StockQueryParameters = {
  ProjectId: number,
  KeyWord: string,
  Sorting: string,
  SkipCount: number;
  MaxResultCount: number;
}

export type StockTypeAvailable= {
  id: string,
  name: string,
  numberOfStockList: number
}

export class StockListModel {
  id: string
  lotNumber?: number
  aspect?: string
  floor?: number
  bedrooms: number
  bathrooms: number
  carPark?: string
  price: number
  makeAvailable: boolean
  creationTime?: string
  floorPlan?: string

  constructor() {
    this.id = ""
    this.lotNumber = 0
    this.aspect = ''
    this.floor = 0
    this.bedrooms = 0
    this.bathrooms = 0
    this.price = 0
    this.makeAvailable = false
  }

  public static assign(obj) {
    if (!obj) return undefined
    const newObj = Object.assign(new StockListModel(), obj)
    return newObj
  }

  public static assigns<T>(objs) {
    const results: any[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

