import agentService from "@services/agent/agentService";
import stockService from "@services/stock/stockService";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import { action, observable } from "mobx";

class StockStore {
  @observable isLoading!: boolean;
  @observable editStock!: any;
  @observable stocks!: PagedResultDto<any>;
  @observable agentStocks!: PagedResultDto<any>;

  @action
  async create(data: any) {
    this.isLoading = true;
    await stockService.create(data).finally(() => (this.isLoading = false));
  }

  @action
  async update(body: any) {
    this.isLoading = true;
    await stockService.update(body).finally(() => (this.isLoading = false));
  }

  @action
  async contactMe(body: any) {
    await agentService.contactMe(body);
  }

  @action
  async get(id: string) {
    this.isLoading = true;
    const result = await stockService.get(id).finally(() => (this.isLoading = false));
    this.editStock = result;
  }

  @action
  async getAll(params: any) {
    this.isLoading = true;
    const result = await stockService.getAll(params).finally(() => (this.isLoading = false));
    this.stocks = result;
  }

  @action
  async getStockListWithFilterAndPaginationByAgentAndStockType(params: any) {
    this.isLoading = true;
    const result = await stockService.getStockListWithFilterAndPaginationByAgentAndStockType(params).finally(() => (this.isLoading = false));
    this.agentStocks = result;
  }
}

export default StockStore;
