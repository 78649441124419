import stockService from '@services/stock/stockService'
import type { PagedResultDto } from '@services/dto/pagedResultDto'
import { action, observable } from 'mobx'
import { StockTypeAvailable } from '@models/stock'

class StockListStore {
    @observable isLoading!: boolean
    @observable stockList!: PagedResultDto<any>
    @observable activeSideMenu: boolean = false
    @observable typeSideMenu: string = ''
    @observable total: number = 0
    @observable pageSize: number = 10
    @observable shareLink: string = ''
    @observable avatarAgent: string = ''
    @observable filterList?: any = {}
    @observable currentPage: number = 0
    @observable isLoadingPage: boolean = false
    @observable dataList: any[] = []
    @observable stockTypeList: StockTypeAvailable[] = []
    @observable currentStockType?: StockTypeAvailable
    @observable currentStockDetail: any = {}

    @action
    async callGetListResponse(projectId: string, filter: string) {
        this.isLoadingPage = true
        try {
            const response = await stockService.getAgentStockList(projectId, filter)
            if (response) {
                this.dataList = response.items
                this.setTotal(response.totalCount)
            }
            this.isLoadingPage = false
        } catch (e) {
            this.isLoadingPage = false
        }
    }
    async callGetStockTypeAvailable(projectId: string) {
        try {
            const response = await stockService.getAgentStockType(projectId)
            if (response) {
                this.stockTypeList = response;
                if (this.stockTypeList?.length > 0) {
                    this.currentStockType = this.stockTypeList[0]
                }
            }
        } catch (e) {
        }
    }
    // async callGetStockDetail(stockId: string) {
    //     try {
    //         const response = await stockService.getStockDetail(stockId);
    //         if (response) {
    //             this.currentStockDetail = response;
    //         }
    //     } catch (e) {
    //         this.isLoadingPage = false;
    //     }
    // }
    async callGetStockForPotentialBuyer(stockId: string) {
        try {
            const response = await stockService.getStockForPotentialBuyer(stockId);
            if (response) {
                this.currentStockDetail = response;
            }
        } catch (e) {
            this.isLoadingPage = false;
        }
    }
    // utils
    @action
    onOpenSideMenu(isActive: boolean) {
        this.activeSideMenu = isActive
    }

    @action
    onChangeTab(val: string) {
        this.typeSideMenu = val || ''
    }

    @action
    setFilter(filter: any) {
        this.filterList = {
            ...this.filterList,
            ...filter
        }
    }

    @action
    setEmptyFilter() {
        this.filterList = {}
    }

    @action
    setPageLoading(stats: boolean) {
        this.isLoadingPage = stats
    }

    @action
    setTotal(total: number) {
        this.total = total
    }
}

export default StockListStore;
