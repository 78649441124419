import http from "../httpService";

class CustomerEOIService {
  //Start Process Bar
  public async apiGetEOICustomerProcessBarByRegisteredCustomerByAgentId(id: number): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerService/GetProcessStepForEOICustomerByEOIId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }
  //End Process Bar
  //#region Start Step1
  public async apiGetBuyerBy(id: number): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerBuyerService/GetEOIBuyerByEOIId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }
  public async apiCreateUpdateBuyer(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerBuyerService/CreateOrUpdateEOIBuyerAndCompany`, params);
    return res || {};
  }
  public async apiDeletedBuyer(id: string): Promise<any> {
    const res = await http.delete(`api/services/app/EOICustomerBuyerService/DeleteEOIBuyerById?id=${id}`);
    return res || {};
  }
  public async apiDeletedCompany(id: string): Promise<any> {
    const res = await http.delete(`api/services/app/EOICustomerCompanyService/DeleteEOICompanyById?id=${id}`);
    return res || {};
  }
  public async apiDeletedDirector(id: string): Promise<any> {
    const res = await http.delete(`api/services/app/EOICustomerDirectorService/DeleteEOIDirectorById?id=${id}`);
    return res || {};
  }
  //End step1
  //Start Step2
  public async apiGetDocumentForEOICustomerUploadDocumentByEOIId(id: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerService/GetDocumentForEOICustomerUploadDocumentByEOIId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }
  public async apiCreateOrUpdateDocumentForCustomerBuyerPrivate(params: any, config?: any): Promise<any> {
    const res = await http.post(`api/Documents/UploadAttachmentForEOIBuyer`, params, config);
    return res || {};
  }
  public async apiCreateOrUpdateDocumentForCustomerBuyerCompany(params: any): Promise<any> {
    const res = await http.put(`api/Documents/UploadAttachmentForEOICompany`, params);
    return res || {};
  }
  //End step2
  //#region Start Step3
  public async apiGetEOICustomerSolicitor(id: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerSolicitorService/GetEOISolicitorByEOIId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }
  public async apiGetEOICustomerSolicitorById(id: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerSolicitorService/GetDetailEOISolicitorById?id=${id}`);
    return res || {};
  }
  public async apiCreateOrUpdateEOICustomerSolicitor(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerSolicitorService/CreateOrUpdateEOISolicitor`, params);
    return res || {};
  }
  //End step3
}

export default new CustomerEOIService();
