import agentService from "@services/agent/agentService";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import { action, observable } from "mobx";
import { FileModel } from "@models/File";

interface IDataListObj {
  id: string,
  isFavorite: boolean,
  displayName: string,
  creationTime: string,
  views: string,
  status: number
}

interface IAgentInfo {
  id: number;
  name: string;
  surname: string;
  gender?: string;
  userInfo: any;
  userName: string;
  passport?: string;
  birthDate?: Date;
  phoneNumber: string;
  displayName: string;
  emailAddress: string;
  urlRegister?: string;
  identityNumber?: string;
}

export interface ICategory {
  id: string,
  file: any
  isShow: boolean,
  uniqueId: string,
  unitName: string,
  totalView: number,
  numberOfCategory: number,
}

interface ICustomerDetail {
  id: string,
  status: number,
  isFavorite: boolean,
  displayName: string,
  phoneNumber: string,
  creationTime: string,
  emailAddress: string,
  statusLasted: number,
  isEoIStarted: boolean,
}

export interface IDetailCategory {
  id: string,
  isShow: boolean,
  items: any,
  totalView: number,
  uniqueId: string,
  unitDetailType: number,
}

export interface IDetailCategoryItem {
  file: any,
  totalView: number,
}

class AgentStore {
  @observable isLoading!: boolean;
  @observable avatar!: FileModel[];
  @observable editAgent!: any;
  @observable agents!: PagedResultDto<any>;
  @observable activeSideMenu: boolean = false;
  @observable typeSideMenu: string = "";

  @observable total: number = 0;
  @observable pageSize: number = 10;
  @observable dataList: IDataListObj[] = [];
  @observable shareLink: string = '';
  @observable customerType: string = '';
  @observable agentInfo?: IAgentInfo;
  @observable avatarAgent: string = '';
  @observable filterList?: any = {};
  @observable currentPage: number = 0;
  @observable isLoadingPage: boolean = false;
  @observable detailCategory: IDetailCategory[] = [];
  @observable dataListResponse: IDataListObj[] = [];
  @observable customerCategory: ICategory[] = [];
  @observable masterDataCustomer: any = {};
  @observable masterDataCustomer2: any = {};
  @observable customerDetailData: ICustomerDetail = {
    id: "",
    status: 0,
    isFavorite: false,
    displayName: "",
    phoneNumber: "",
    creationTime: "",
    emailAddress: "",
    statusLasted: 0,
    isEoIStarted: false
  };

  @action
  async create(data: any) {
    const body = {
      ...data,
      isActive: true,
    };
    await agentService.create(body);
  }

  @action
  async update(body: any) {
    await agentService.update(body);
  }

  @action
  async contactMe(body: any) {
    await agentService.contactMe(body);
  }

  @action
  async activateOrDeactivate(id: number, isActive) {
    await agentService.activateOrDeactivate(id, isActive);
  }

  @action
  async get(id: number, projectId?) {
    const result = await agentService.get(id, projectId);
    this.editAgent = result;
  }

  @action
  async getAll(params: any) {
    this.isLoading = true;
    const result = await agentService.getAll(params).finally(() => (this.isLoading = false));
    this.agents = result;
  }

  @action
  async sendEmailEoIStarted(params: any) {
    this.isLoading = true;
    await agentService.sendEmailEoIStarted(params).finally(() => (this.isLoading = false));
  }

  @action
  async getAvatar(uniqueId: string) {
    const result = await agentService.getAvatar(uniqueId);

    if (result?.length > 0) {
      this.avatar = result;
    } else {
      this.avatar = [];
    }
  }

  async upload(uniqueId, files) {
    await agentService.upload(uniqueId, files);
  }

  @action
  async getExistEmail(emailAddress: string) {
    const result = await agentService.getExistEmail(emailAddress);
    this.editAgent = result;
  }

  // agent list 
  @action
  async callGetAvatar() {
    try {
      const { data } = await agentService.apiAvatarAgent();
      if (data.success) {
        const { result } = data;
        this.avatarAgent = `data:image/jpeg;base64,${result.profilePicture}`
      }
    } catch (e) {
      console.log('== e-get-avatar ==>', e);
    }
  }
  @action
  async callGetList(id?: any, filter: string = '') {
    const fake_id = id;
    this.isLoadingPage = true;
    try {
      const { data } = await agentService.apiListCustomes(fake_id, filter);
      if (data.success) {
        const { result } = data;
        this.dataList = result?.data?.items;
        this.masterDataCustomer = result;
      }
      this.isLoadingPage = false;
    } catch (e) {
      console.log('e-get-list ==>', e);
      this.isLoadingPage = false;
    }
  }
  @action
  async callGetListResponse(id?: any) {
    const fake_id = id;
    this.isLoadingPage = true;
    try {
      const { data } = await agentService.apiListCustomes(fake_id, '&status=1');
      if (data.success) {
        const { result } = data;
        this.dataListResponse = result?.data?.items;
        this.masterDataCustomer2 = result;
      }
      this.isLoadingPage = false;
    } catch (e) {
      console.log('e-get-list ==>', e);
      this.isLoadingPage = false;
    }
  }
  @action
  async callInfoAgent() {
    try {
      const { data } = await agentService.apiGetAgentInfo();
      if (data.success) {
        const { result } = data;
        this.agentInfo = result.user;
      }
    } catch (e) {
      console.log('e-info-agent ==>', e);
    }
  }
  @action
  async callChangePassword(params: any) {
    try {
      const { data } = await agentService.apiChangePassword(params);
      return data;
    } catch (e) {
      console.log('e-change-pass ===>', e);
      return null;
    }
  }
  @action
  async callDetailCustomer(id: string) {
    this.isLoadingPage = true;
    try {
      const { data } = await agentService.apiDetailCustomer(id);
      if (data.success) {
        const { result } = data;
        this.customerDetailData = result;
      }
      this.isLoadingPage = false;
      return data;
    } catch (e) {
      console.log('== e-detail-customer ==>', e)
      this.isLoadingPage = false;
      return null;
    }
  }
  @action
  async callFavorite(props: any) {
    const params = {
      userId: props.id,
      projectId: props.projectId,
      isFavorite: props.isFavorite
    }
    this.isLoadingPage = true;
    try {
      const { data } = await agentService.apiFavoriteCustomer(params);

      this.isLoadingPage = false;
      return data;
    } catch (e) {
      console.log('e-favorite ===>', e);

      this.isLoadingPage = false;
      return null
    }
  }
  @action
  async callCategory(props: any) {
    this.isLoadingPage = true;
    try {
      const params = {
        projectId: props.projectId,
        userId: props.userId,
      }
      const { data } = await agentService.apiGetCategory(params)
      this.customerCategory = data?.result;
      console.log('category ====>', data);
      this.isLoadingPage = false;
    } catch (e) {
      console.log('== e-category ==>', e);
      this.isLoadingPage = false;
    }
  }
  @action
  async callSendEmailUser({ emailAddress, content }) {
    try {
      const { data } = await agentService.apiSendEmail({ emailAddress, content });
      return data;
    } catch (e) {
      console.log('== e-send-mail ===>', e);
      return null;
    }
  }
  @action
  async callDetailsCategory(params: any) {
    this.isLoadingPage = true;
    try {
      const { data } = await agentService.apiGetDetailCategory(params);
      this.detailCategory = data.result;
      this.isLoadingPage = false;
    } catch (e) {
      console.log('== e-detail-category ===>', e);
      this.isLoadingPage = false;
    }
  }
  @action
  async callToggleShowHide({ refId, userId, type }) {
    this.isLoadingPage = true;
    try {
      const params = {
        type, // Category = 0 - Item = 1
        refId,
        userId
      }
      const { data } = await agentService.apiToggleShowHide(params);
      this.isLoadingPage = false;
      return data;
    } catch (e) {
      this.isLoadingPage = false;
      console.log('== e-show-hide ===>', e);
      return null;
    }
  }
  @action
  async callSendEmailOffline(body: any) {
    try {
      const { data } = await agentService.apiSendEmailToCustomerOffline(body);
      return data;
    } catch (e) {
      console.log("==== e-send-email-offline =====>", e);
      this.isLoadingPage = false;
    }
  }
  @action
  async callActiveAssets({ userId }) {
    this.isLoadingPage = true;
    try {
      const { data } = await agentService.apiActiveAllAssets({ userId });
      this.isLoadingPage = false;
      return data;
    } catch (e) {
      console.log("==== e-active-all ===>", e);
      this.isLoadingPage = false;
    }
  }

  // utils
  @action
  onOpenSideMenu(isActive: boolean) {
    this.activeSideMenu = isActive;
  }
  @action
  onChangeTab(val: string) {
    this.typeSideMenu = val || "";
  }
  @action
  setFilter(filter: any) {
    this.filterList = {
      ...this.filterList,
      ...filter
    }
  }
  @action
  setEmptyFilter() {
    this.filterList = {}
  }
  @action
  setPageLoading(stats: boolean) {
    this.isLoadingPage = stats;
  }
  @action
  setTotal(total: number) {
    this.total = total
  }

  @action
  setCustomerType(customerType: string) {
    this.customerType = customerType
  }
}

export default AgentStore;
