import type { PagedResultDto } from "../../services/dto/pagedResultDto";
import http from "../httpService";
import { L, LNotification } from "../../lib/abpUtility";
import { notifyError, notifySuccess } from "../../lib/helper";
import { RowAgentModel } from "@models/agent/RowAgent";
import { FileModel } from "@models/File";
import { AgentDetailModel } from "@models/agent/AgentDetailModel";

class AgentService {
  public async create(body: any) {
    const result = await http.post("api/services/app/Agent/Create", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async update(body: any) {
    const result = await http.put("api/services/app/Agent/Update", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async activateOrDeactivate(id: number, isActive) {
    const result = await http.post(
      "api/services/app/Agent/Active",
      { id },
      { params: { isActive } }
    );
    return result.data;
  }

  public async get(id: number, projectId?): Promise<any> {
    if (!id) {
      notifyError(L("Error"), L("EntityNotFound"));
    }

    const result = await http.get("api/services/app/Agent/GetDetail", {
      params: { id, projectId },
    });

    return AgentDetailModel.assign(result.data.result);
  }

  public async getAgentAttachment(userId?: any): Promise<any> {
    const res = await http.get("api/services/app/Agent/GetAgentAttachment", {
      params: userId,
    });
    const { result } = res.data;

    return result;
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Agent/GetAll", {
      params: params,
    });
    const { result } = res.data;
    result.items = RowAgentModel.assigns(result.items);
    return result;
  }

  public async sendEmailEoIStarted(body: any): Promise<any> {
    const res = await http.post("api/services/app/Agent/SendEmailEoIStarted", body);
    const { result } = res.data;

    return result;
  }

  public async getAvatar(uniqueId: string): Promise<FileModel[]> {
    if (!uniqueId) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }

    const res = await http.get("api/services/app/Documents/GetDocuments", {
      params: { uniqueId },
    });
    const result = res.data.result;
    return FileModel.assigns(result || []);
  }

  public async upload(uniqueId, files: any) {
    console.log(uniqueId);
    const data = new FormData();
    data.append("file", files);

    const result = await http.post("api/Documents/UploadProfile", data, {
      headers: {
        "content-type": "multipart/form-data",
      },
      params: { uniqueId },
    });
    return result.data.result;
  }

  public async getExistEmail(emailAddress: string): Promise<any> {
    const res = await http.get("api/services/app/Agent/GetExistEmail", {
      params: { emailAddress },
    });
    const { result } = res.data;

    if (result) {
      return AgentDetailModel.assign(res.data.result);
    } else {
      return null;
    }
  }

  // agent customers
  public async apiListCustomes(id: string, filter: string = ''): Promise<any> {
    const res = await http.get(`api/services/app/Agent/GetAllCustomerRegistered?projectId=${id}${filter}`);
    return res || {};
  }
  public async apiDetailCustomer(id: string): Promise<any> {
    const res = await http.get(`api/services/app/Agent/GetDetailCustomerRegistered?userId=${id}`);
    return res || {};
  }
  public async apiFavoriteCustomer(params: any): Promise<any> {
    const res = await http.post(`api/services/app/Agent/Favorite`, params);
    return res || {};
  }
  public async apiGetAgentInfo(): Promise<any> {
    const res = await http.get(`api/services/app/Session/GetCurrentLoginInformations?culture=vi-VN`);
    return res || {};
  }
  public async apiChangePassword(params: any): Promise<any> {
    const res = await http.post(`api/services/app/User/ChangePassword?culture=vi-VN`, params);
    return res || {};
  }
  public async apiAvatarAgent(): Promise<any> {
    const res = await http.get(`api/services/app/Profile/GetProfilePicture?culture=en-US`);
    return res || {};
  }
  public async apiSendEmail(params: any): Promise<any> {
    const res = await http.post(`api/services/app/Agent/SendEmailContactUser`, params);
    return res || {};
  }
  public async apiGetCategory({ projectId, userId }): Promise<any> {
    const res = await http.get(`api/services/app/Agent/GetAssetUnit?ProjectId=${projectId}&UserId=${userId}`);
    return res || {};
  }
  public async apiGetDetailCategory({ unitId, userId }): Promise<any> {
    const res = await http.get(`api/services/app/Agent/GetAssetUnitDetail?UnitId=${unitId}&UserId=${userId}`);
    return res || {};
  }
  public async apiToggleShowHide(params: any): Promise<any> {
    const res = await http.post(`api/services/app/Agent/ShowHide`, params);
    return res || {};
  }
  public async contactMe(params: string): Promise<any> {
    const res = await http.post(`api/services/app/Agent/ContactMe`, params);
    return res || {};
  }
  public async apiSendEmailToCustomerOffline(params: any): Promise<any> {
    const res = await http.post(`api/services/app/Agent/SendEmailToCustomerOffline?culture=vi-VN`, params);
    return res || {};
  }
  public async apiActiveAllAssets(params: any): Promise<any> {
    const res = await http.post(`api/services/app/Agent/ActiveAll?userId=${params.userId}`);
    return res || {};
  }
}

export default new AgentService();
