import * as React from "react";

import Router from "./components/Layout/Router";
import SessionStore from "./stores/sessionStore";
import SignalRAspNetCoreHelper from "./lib/signalRAspNetCoreHelper";
import Stores from "./stores/storeIdentifier";
import { inject } from "mobx-react";
import withSplashScreen from "@components/Layout/SplashScreen";

import AppConsts from "@lib/appconst";
import { isMobile } from "react-device-detect";

const { authorization } = AppConsts;

export interface IAppProps {
  sessionStore?: SessionStore;
}

@inject(Stores.SessionStore)
class App extends React.Component<IAppProps> {
  state = { isLoading: true };

  async componentDidMount() {
    if (isMobile) {
      const root: any = document.getElementById('root')
      if (root) {
        root.style.maxHeight = `${window.innerHeight}px`;
      }
    }

    // const initialValue = document.body.style as any

    // if (window.devicePixelRatio === 1.25) {
    //   initialValue.zoom = '80%'
    // }

    await this.props.sessionStore!.getCurrentLoginInformations();

    // TODO: Hàm dưới viết vì mục đích luôn luôn Redirect sang login nếu chưa đăng nhập
    // if (
    //   !this.props.sessionStore!.currentLogin.user &&
    //   window.location.pathname !== "/account/reset-password" &&
    //   window.location.pathname !== "/account/login"
    // ) {
    //   window.location.href = "/account/login";
    // }

    if (
      !!this.props.sessionStore!.currentLogin.user &&
      this.props.sessionStore!.currentLogin.application.features["SignalR"]
    ) {
      SignalRAspNetCoreHelper.initSignalR();
    }

    if (
      !!this.props.sessionStore!.currentLogin.user &&
      localStorage.getItem(authorization.projectId)
    ) {
      await Promise.all([
        this.props.sessionStore?.getMyProfilePicture(),
        this.props.sessionStore!.getOwnProjects({}),
      ]);
    }

    this.setState({ isLoading: false });
  }

  componentWillUnmount() {}

  public render() {
    if (this.state.isLoading) return null;

    return <Router />;
  }
}

export default withSplashScreen(App);
