import customerEoiService from "@services/customerEoi/customerEoiService";
import { map } from "lodash";
import { action, observable } from "mobx";

export interface IBuyerForm {
  creatorUserId?: any,
  creationTime?: string,
  deleteUserId?: any,
  deletionTime?: string,
  middleName?: string,
  firstName?: string,
  isDeleted?: true,
  lastName?: string,
  address?: string,
  email?: string,
  phone?: string,
  state?: any,
  city?: string,
  type?: any,
  id?: any
}

export interface IUloadPrivateForm {
  creatorUserId?: any,
  creationTime?: string,
  deleteUserId?: any,
  uniqueId ?: any,
  documentName?: string,
  documentTypeId?: number,
  uploadDate?: string
}

export interface IDocumentBuyerForm {
  referenceId?: any,
  fileUrl?: string,
  mimeType?: any,
  originalFileName ?: any,
  moduleName?: any,
  fileName?: any,
  documentName?: any,
  documentTypeId?: any,
  id?: any
}

export interface ISolicitorForm {
  creatorUserId?: any,
  creationTime?: string,
  firmName?: any,
  solicitorName?: string,
  email?: string,
  phone?: string,
  address?: true,
  city?: string,
  state?: string,
  purchasingType?: string,
  registeredCustomerByAgentId?: string,
  id?: any
}
class CustomerEOIStore {
  @observable isLoading!: boolean;
  // 0 [None]  // 1 [Present]  // 2 [Error]  // 3 [Done]
  @observable registeredCustomerByAgentId: number = 0;
  @observable progressData: any[] = [{
    name: 'Buyer details',
    type: 'detail',
    status: 1, // 3,
    isShow: true
  }, {
    name: 'Upload documents',
    type: 'upload',
    status: 0,
    isShow: false
  }, {
    name: 'Solicitor details',
    type: 'solicitor',
    status: 0,
    isShow: false
  }, {
    name: 'Payment',
    type: 'payment',
    status: 0,
    isShow: false
  }, {
    name: 'Review & sign',
    type: 'review',
    status: 0,
    isShow: false
  }];

  @observable step: number = 1;
  @observable dataBuyer: any = [];
  @observable dataDocumentBuyer: any = [];
  @observable dataSolicitor: any = {};

  @action
  async callGetEIOCustomerProcessBarByRegisteredCustomerByAgentId(id?: any) {
    try {
      const { data } = await customerEoiService.apiGetEOICustomerProcessBarByRegisteredCustomerByAgentId(id)
      return data;
    } catch (e) {
      console.log('=== e-get-eoi-customer-process-bar-by ==>', e);
    }
  }

  //#region step1
  @action
  async callGetBuyerBy(id: number) {
    try {
      const { data } = await customerEoiService.apiGetBuyerBy(id);
      if (data.success) {
        await this.setCurrentProcessBar(0);
        this.dataBuyer = map(data.result, (con, conI) => {
          return {
            ...(con as object),
            type: con.type == 2,
          }
        })
      }
      return data;
    } catch (e) {
      console.log('=== e-get-buyer-by ==> ', e);
    }
  }

  @action
  async callCreateUpdateBuyerByEOI(params: IBuyerForm) {
    try {
      const { data } = await customerEoiService.apiCreateUpdateBuyer(params)
      return data;
    } catch (e) {
      console.log('=== e-created-update-buyer ==> ', e);
    }
  }
  @action
  async callDeletedBuyer(id: string) {
    try {
      const { data } = await customerEoiService.apiDeletedBuyer(id)
      return data;
    } catch (e) {
      console.log('=== e-update-buyer ==> ', e);
    }
  }
  @action
  async callDeletedCompany(id: string) {
    try {
      const { data } = await customerEoiService.apiDeletedCompany(id)
      return data;
    } catch (e) {
      console.log('=== e-update-buyer ==> ', e);
    }
  }
  @action
  async callDeletedDirector(id: string) {
    try {
      const { data } = await customerEoiService.apiDeletedDirector(id)
      return data;
    } catch (e) {
      console.log('=== e-update-buyer ==> ', e);
    }
  }
  //#endregion
  //#region step2
  @action
  async getDocumentForEOICustomerUploadDocumentByEOIId(id?: any) {
    try {
      const { data } = await customerEoiService.apiGetDocumentForEOICustomerUploadDocumentByEOIId(id)
      return data;
    } catch (e) {
      console.log('=== e-get-document-buyer-by ==>', e);
    }
  }

  @action
  async createOrUpdateDocumentForCustomerBuyerCompany(params: IUloadPrivateForm) {
    try {
      const { data } = await customerEoiService.apiCreateOrUpdateDocumentForCustomerBuyerCompany(params)
      return data;
    } catch (e) {
      console.log('=== e-create-or-update-buyer-company ==>', e);
    }
  }

  @action
  async callCreateOrUpdateDocumentBuyerBy({param, config}) {
    console.log('run ===>')
    try {
      const { data } = await customerEoiService.apiCreateOrUpdateDocumentForCustomerBuyerPrivate(param);
      return data;
    } catch (e) {
      console.log('=== e-post-creat-or-update-document-buyer-by ==> ', e);
    }
  }
  //#endregion
  //#region step3
  @action
  async callGetEOICustomerSolicitorByEOIId(id?: any) {
    try {
      const { data } = await customerEoiService.apiGetEOICustomerSolicitor(id);
      return data;
    } catch (e) {
      console.log('=== e-get-document-buyer-by ==>', e);
    }
  }

  @action
  async callCreateOrUpdateEOICustomerSolicitor(params: ISolicitorForm) {
    try {
      const { data } = await customerEoiService.apiCreateOrUpdateEOICustomerSolicitor(params)
      return data;
    } catch (e) {
      console.log('=== e-create-or-update-buyer-company ==>', e);
    }
  }
  //#endregion

  // utils 
  @action
  setProgressData(data: any) {
    this.progressData = data;
  }
  @action
  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  @action
  setDataBuyer(data: any) {
    this.dataBuyer = data;
  }

  @action
  setDataDocumentBuyer(data: any) {
    this.dataDocumentBuyer = data
  }

  @action
  setSolicitor(data: any) {
    this.dataSolicitor = data
  }

  @action
  setStep(step: number) {
    this.step = step
  }

  @action
  setCustomerByAgentId(id: number) {
    this.registeredCustomerByAgentId = id
  }

  @action
  async setCurrentProcessBar(step: number) {
    try {
      const { data } = await customerEoiService.apiGetEOICustomerProcessBarByRegisteredCustomerByAgentId(this.registeredCustomerByAgentId)
      if (data && data?.success && data?.result) {
        data?.result?.map((item) => {
          switch (item.stepName) {
            case "Step 1": this.progressData[0].status = item.status;
            case "Step 2": this.progressData[1].status = item.status;
            case "Step 3": this.progressData[2].status = item.status;
            case "Step 4": this.progressData[3].status = item.status;
            case "Step 5": this.progressData[4].status = item.status;
            default:
          }
        });
      }
      this.progressData.map((item, index) => {
        if (index === step){
          item.isShow = true;
        } else {
          item.isShow = false;
        }
      });
    } catch (e) {
      console.log('=== e-get-eoi-customer-process-bar-by ==>', e);
    }
  }
}

export default CustomerEOIStore;
