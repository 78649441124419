export default class Stores {
  static AuthenticationStore = "authenticationStore";
  static RoleStore = "roleStore";
  static TenantStore = "tenantStore";
  static UserStore = "userStore";
  static SessionStore = "sessionStore";
  static AccountStore = "accountStore";
  static LanguageStore = "languageStore";
  static AppDataStore = "appDataStore";

  // Master data
  static TruckBrandStore = "truckBrandStore";
  static TruckTypeStore = "truckTypeStore";
  static ProductTypeStore = "productTypeStore";
  static RatingBadgeStore = "ratingBadgeStore";
  static TransportationCostStore = "transportationCostStore";
  static MasterDataStore = "masterDataStore";

  // Resident & Staff & Shop owner
  static StaffStore = "staffStore";
  static PartnerStore = "partnerStore";
  static CustomerStore = "customerStore";

  // News & Event
  static NewsStore = "newsStore";
  static NewsCategoryStore = "newsCategoryStore";
  static FeedbackStore = "feedbackStore";
  static AnnouncementStore = "announcementStore";

  // Workflow
  static WfStatusStore = "wfStatusStore";
  static WfPriorityStore = "wfPriorityStore";
  static WfCustomFieldStore = "wfCustomFieldStore";
  static WfRoleStore = "wfRoleStore";
  static WfTrackerStore = "wfTrackerStore";
  static WfConfigurationStore = "wfConfigurationStore";
  static WorkflowStore = "workflowStore";

  // File
  static FileStore = "fileStore";

  // Common
  static AuditLogStore = "auditLogStore";
  static CommentStore = "commentStore";
  static NotificationTemplateStore = "notificationTemplateStore";
  static TermConditionStore = "termConditionStore";

  // Reminder
  static ReminderStore = "reminderStore";
  // Client Management
  static CompanyStore = "companyStore";
  static ContactStore = "contactStore";

  //organizationUnit
  static OrganizationUnitStore = "organizationUnitStore";
  // Activity

  static ActivityStore = "activityStore";
  static CallStore = "callStore";
  static MailStore = "mailStore";
  static SiteVisitStore = "siteVisitStore";
  static MeetingStore = "meetingStore";
  static CalendarStore = "calendarStore";
  static DepositStore = "depositStore";

  // Project
  static ProjectStore = "projectStore";
  static RequirementStore = "requirementStore";
  static CategoryStore = "categoryStore";
  static ListingStore = "listingStore";
  static DocumentStore = "documentStore";

  // Campaign
  static CampaignStore = "campaignStore";
  static TargetStore = "targetStore";

  static OpportunityCommercialStore = "opportunityCommercialStore";

  static LeaseAgreementStore = "leaseAgreementStore";
  static SettingVatStore = "settingVatStore";

  static DashboardStore = "dashboardStore";

  static AgentStore = "agentStore";
  static CustomerEOIStore = 'customerEOIStore';
  static StockListStore = 'stockListStore';
  static StockTypeStore = "stockTypeStore";
  static StockStore = "stockStore";

  static AdminEOIStore = "adminEOIStore";
}
