import RoleStore from "./administrator/roleStore";
import TenantStore from "./administrator/tenantStore";
import UserStore from "./administrator/userStore";
import SessionStore from "./sessionStore";
import AuthenticationStore from "./authenticationStore";
import AccountStore from "./accountStore";
import LanguageStore from "./administrator/languageStore";

import StaffStore from "./member/staff/staffStore";
import NewsStore from "./communication/newsStore";
import FeedbackStore from "./communication/feedbackStore";
import NewsCategoryStore from "./communication/newsCategoryStore";
import AnnouncementStore from "./communication/announcementStore";

import WorkflowStore from "./workflow/workflowStore";
import WfStatusStore from "./workflow/wfStatusStore";
import WfRoleStore from "./workflow/wfRoleStore";
import WfPriorityStore from "./workflow/wfPriorityStore";
import WfCustomFieldStore from "./workflow/wfCustomFieldStore";
import WfTrackerStore from "./workflow/wfTrackerStore";
import WfConfigurationStore from "./workflow/wfConfigurationStore";

import FileStore from "./common/fileStore";
import AuditLogStore from "./common/auditLogStore";
import CommentStore from "./common/commentStore";

import NotificationTemplateStore from "./notificationTemplate/notificationTemplateStore";
import TermConditionStore from "./administrator/termConditionStore";
import ReminderStore from "@stores/common/reminderStore";

import AppDataStore from "./appDataStore";

import CompanyStore from "./clientManagement/companyStore";
import ContactStore from "./clientManagement/contactStore";

import ActivityStore from "./activity/activityStore";
import DepositStore from "./activity/depositStore";
import CallStore from "./activity/callStore";
import MailStore from "./activity/mailStore";
import SiteVisitStore from "./activity/siteVisitStore";
import MeetingStore from "./activity/meetingStore";
import CalendarStore from "./activity/calendarStore";

import ProjectStore from "./projects/projectStore";
import RequirementStore from "./projects/requirementStore";
import CategoryStore from "./projects/categoryStore";
import ListingStore from "./projects/listingStore";
import DocumentStore from "./document/documentStore";

import OrganizationUnitStore from "./organizationUnit/organizationUnitStore";
import LeaseAgreementStore from "./communication/leaseAgreementStore";
import SettingVatStore from "./settingVatStore";
import DashboardStore from "./dashboardStore";

import AgentStore from "./agent/agentStore";
import CustomerStore from "./customer/customerStore";
import CustomerEOIStore from './customerEOI/customerEOIStore'
import StockListStore from '@stores/stock/stockListStore'
import StockStore from "@stores/stock/stockStore"
import StockTypeStore from "@stores/stockType/stockTypeStore"
import AdminEOI from "./adminEOI/adminEOI";

export default function initializeStores() {
  return {
    authenticationStore: new AuthenticationStore(),
    roleStore: new RoleStore(),
    tenantStore: new TenantStore(),
    userStore: new UserStore(),
    sessionStore: new SessionStore(),
    accountStore: new AccountStore(),
    languageStore: new LanguageStore(),
    appDataStore: new AppDataStore(),

    staffStore: new StaffStore(),

    // News & Event
    newsStore: new NewsStore(),
    newsCategoryStore: new NewsCategoryStore(),
    feedbackStore: new FeedbackStore(),
    announcementStore: new AnnouncementStore(),

    workflowStore: new WorkflowStore(),
    wfStatusStore: new WfStatusStore(),
    wfRoleStore: new WfRoleStore(),
    wfPriorityStore: new WfPriorityStore(),
    wfTrackerStore: new WfTrackerStore(),
    wfCustomFieldStore: new WfCustomFieldStore(),
    wfConfigurationStore: new WfConfigurationStore(),
    customerStore: new CustomerStore(),
    auditLogStore: new AuditLogStore(),
    fileStore: new FileStore(),
    commentStore: new CommentStore(),
    notificationTemplateStore: new NotificationTemplateStore(),
    termConditionStore: new TermConditionStore(),
    reminderStore: new ReminderStore(),

    companyStore: new CompanyStore(),
    contactStore: new ContactStore(),

    activityStore: new ActivityStore(),
    depositStore: new DepositStore(),
    callStore: new CallStore(),
    mailStore: new MailStore(),
    siteVisitStore: new SiteVisitStore(),

    meetingStore: new MeetingStore(),
    calendarStore: new CalendarStore(),

    projectStore: new ProjectStore(),
    requirementStore: new RequirementStore(),
    categoryStore: new CategoryStore(),
    listingStore: new ListingStore(),
    settingVatStore: new SettingVatStore(),
    organizationUnitStore: new OrganizationUnitStore(),
    leaseAgreementStore: new LeaseAgreementStore(),

    dashboardStore: new DashboardStore(),

    agentStore: new AgentStore(),
    stockTypeStore: new StockTypeStore(),
    stockStore: new StockStore(),
    documentStore: new DocumentStore(),
    customerEOIStore: new CustomerEOIStore(),
    stockListStore: new StockListStore(),
    adminEOIStore: new AdminEOI(),
  }
}
