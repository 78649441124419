import agentService from "@services/agent/agentService";
import stockTypeService from "@services/stockType/stockTypeService";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import { action, observable } from "mobx";

class StockTypeStore {
  @observable isLoading!: boolean;
  @observable editStockType!: any;
  @observable selectedStockType!: any;
  @observable stockTypes!: PagedResultDto<any>;
  @observable availableStockTypes!: PagedResultDto<any>;

  @action
  async initStockType() {
    this.editStockType = {
      name: "",
      virtualWalkthrough: {},
      video: {},
    };
  }

  @action
  async create(data: any) {
    await stockTypeService.create(data);
  }

  @action
  async update(body: any) {
    await stockTypeService.update(body);
  }

  @action
  async contactMe(body: any) {
    await agentService.contactMe(body);
  }

  @action
  async activateOrDeactivate(id: number, isActive) {
    await agentService.activateOrDeactivate(id, isActive);
  }

  @action
  async get(id: string) {
    this.isLoading = true;
    const result = await stockTypeService.get(id).finally(() => (this.isLoading = false));
    this.editStockType = result;
    this.selectedStockType = result;
  }

  @action
  async getAll(params: any) {
    this.isLoading = true;
    const result = await stockTypeService.getAll(params).finally(() => (this.isLoading = false));
    this.stockTypes = result;
  }

  @action
  async getAllWithoutSearch() {
    this.isLoading = true;
    const result = await stockTypeService.getAllWithoutSearch().finally(() => (this.isLoading = false));
    this.stockTypes = result;
  }

  @action
  async getAllStockTypeAvailable(params: any) {
    this.isLoading = true;
    const result = await stockTypeService.getAllStockTypeAvailable(params).finally(() => (this.isLoading = false));
    this.availableStockTypes = result;
  }
}

export default StockTypeStore;
