import type { PagedResultDto } from "../dto/pagedResultDto";
import http from "../httpService";
import { L, LNotification } from "../../lib/abpUtility";
import { notifyError, notifySuccess } from "../../lib/helper";
import { RowAgentModel } from "@models/agent/RowAgent";
import { FileModel } from "@models/File";
import { StockTypeDetailModel } from "@models/stockType/StockTypeDetailModel";

class StockService {
  public async create(body: any) {
    const result = await http.post("api/services/app/ProjectStockType/Create", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async update(body: any) {
    const result = await http.put("api/services/app/ProjectStockType/Update", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async get(id: string): Promise<any> {
    if (!id) {
      notifyError(L("Error"), L("EntityNotFound"));
    }

    const result = await http.get("api/services/app/ProjectStockType/GetProjectStockTypeDetail", {
      params: { stockTypeId: id },
    });

    return StockTypeDetailModel.assign(result.data.result);
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/ProjectStockType/GetStockTypeWithFilterAndPagination", {
      params: params,
    });
    const { result } = res.data;
    result.items = RowAgentModel.assigns(result.items);
    return result;
  }

  public async getAllWithoutSearch(): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/ProjectStockType/GetAllProjectStockTypes");
    const { result } = res.data;
    result.items = RowAgentModel.assigns(result);
    return result;
  }

  public async getAllStockTypeAvailable(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/ProjectStockType/GetAllStockTypeAvailable", {
      params: params,
    });
    const { result } = res.data;
    result.items = RowAgentModel.assigns(result);
    return result;
  }

  public async getFile(uniqueId: string): Promise<FileModel[]> {
    if (!uniqueId) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }

    const res = await http.get("api/services/app/Documents/GetDocuments", {
      params: { uniqueId },
    });
    const result = res.data.result;
    return FileModel.assigns(result || []);
  }
}

export default new StockService();
